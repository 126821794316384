import { transparentize } from "./helperFn";

export const colors = {
  primary: "#0D6F18",
  primary90: transparentize("#0D6F18", 0.9),
  primary80: transparentize("#0D6F18", 0.8),
  primary70: transparentize("#0D6F18", 0.7),
  primary30: transparentize("#0D6F18", 0.3),
  primaryDark: "#224d00",
  primaryDark90: transparentize("#224d00", 0.9),
  primaryHover: "#237d2a",
  backgroundDark: "#0b1620",
  secondary: "#0D6F18",
  text: "#333333",
  textLight: "#505050",
  textDarkMode: "#ffffff",
  textDarkMode85: transparentize("#ffffff", 0.85),
  textDarkMode80: transparentize("#ffffff", 0.8),
  btn: "#4B5A57",
  cta_btn: "#0D6F18",
  cta_btn_text: "#fff",
  loyalty_primary: "#579b3c",
  standout_text: "#579b3c",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  category_colored_header: "#E1EBD2",
  clickable_text: "#0066ff",
  featuredTag: "#ffaa00",
  hamburger: "#224d00",
  menu_items: "#222",
};

export const styles = {
  btn_border_radius: "5px",
  card_border_radius: "10px",
  bullet_point_icon: "💎  ",
  banner_padding_desktop: "5rem",
  banner_padding_mobile: "3rem",
};
