import React from "react";
import { SOCIAL_MEDIA } from "../../../constants/CompanyConstants";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { useTranslation } from "react-i18next";

const MobileWidgets = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {SOCIAL_MEDIA.length > 0 && (
        <IconsWrapper>
          {SOCIAL_MEDIA.map((item, key) => {
            return (
              <a
                href={item.link}
                key={key}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={t("go_to_social_media", { name: item.name })}
              >
                <i className={item.icon} />
              </a>
            );
          })}
        </IconsWrapper>
      )}
    </Wrapper>
  );
};

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  i {
    font-size: 28px;
    color: ${colors.primary};
  }
`;

const Wrapper = styled.div`
  padding: 2rem;
`;

export default MobileWidgets;
