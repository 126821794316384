import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useFirestore } from "react-redux-firebase";
import { FIRESTORE_USER_FEEDBACK_TABLE } from "../../../constants/FirebaseConstants";
import useIsMobile from "../../hooks/useIsMobile";
import { colors } from "../../../utils/theme";
import { AnimatePresence, motion } from "framer-motion";
import { device } from "../../../utils/breakpoints";

const { TextArea } = Input;

export default function FeedbackWidget() {
  const user = useSelector((state) => state.firebaseReducer.profile);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const firestore = useFirestore();
  const [feedbackForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (open) {
      setOpen(false);
      setInputValue(null);
      feedbackForm.resetFields();
    } else setOpen(true);
  };

  const handleSubmit = () => {
    let messageField = feedbackForm.getFieldValue("message");
    setIsLoading(true);
    const object = {
      email: user.email,
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      message: messageField,
      createdAt: dayjs().unix(),
    };
    saveUserFeedback(object)
      .then(() => {
        handleClick();
        setIsLoading(false);
        notification["success"]({
          message: t("notifications.feedback_send_success"),
        });
      })
      .catch((err) => {
        console.log(err);
        handleClick();
        setIsLoading(false);
        notification["warning"]({
          message: t("notifications.feedback_send_failed"),
        });
      });
  };

  const saveUserFeedback = async (object) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection(FIRESTORE_USER_FEEDBACK_TABLE)
        .add(object)
        .then(function (docRef) {
          resolve(docRef.id);
        })
        .catch((err) => reject(err.message));
    });
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const handleEscapeKey = (e) => {
      if (open && e.key === "Escape") {
        handleClick();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [open]);

  return (
    <FeedbackWidgetWrapper>
      {isMobile ? (
        <MobileFeedbackButton
          aria-controls={"feedback-popover"}
          aria-expanded={open}
          onClick={handleClick}
        >
          <p>{t("give_feedback")}</p>
          <img src={"/assets/img/icons/feedback_dark.svg"} alt={""} />
        </MobileFeedbackButton>
      ) : (
        <FeedbackButton
          aria-controls={"feedback-popover"}
          aria-expanded={open}
          onClick={handleClick}
        >
          <p>{t("feedback")}</p>
          <img src={"/assets/img/icons/feedback.svg"} alt={""} />
        </FeedbackButton>
      )}
      <AnimatePresence>
        {open && (
          <FeedbackPopover
            id={"feedback-popover"}
            initial={{ opacity: 0, translateY: -6 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: -6 }}
          >
            <div className={"title-container"}>
              <p className={"title"}>{t("feedback_title")}</p>
              <button
                onClick={handleClick}
                aria-label={t("close_feedback_widget")}
                className={"close-button"}
              >
                <CloseOutlined />
              </button>
            </div>
            <Form form={feedbackForm} onFinish={handleSubmit}>
              <Form.Item style={{ marginBottom: "1rem" }} name={"message"}>
                <TextArea
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={t("feedback_placeholder")}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  className={"styled-text-area"}
                />
              </Form.Item>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  type={"primary"}
                  htmlType="submit"
                  block
                  loading={isLoading}
                  disabled={!inputValue}
                >
                  {t("send")}
                </Button>
              </Form.Item>
            </Form>
          </FeedbackPopover>
        )}
      </AnimatePresence>
    </FeedbackWidgetWrapper>
  );
}

const FeedbackWidgetWrapper = styled.div`
  position: relative;
`;

const FeedbackPopover = styled(motion.div)`
  position: absolute;
  background: #fff;
  min-width: 300px;
  width: 100%;
  z-index: 1;
  padding: 12px;
  border-radius: 6px;
  color: ${colors.text};
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.16),
    0 3px 6px -4px rgba(0, 0, 0, 0.24),
    0 9px 28px 8px rgba(0, 0, 0, 0.1);

  p {
    margin: 0;
    color: inherit;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 0.5rem;

    & button.close-button {
      display: grid;
      place-content: center;
      width: 24px;
      height: 24px;
      font-size: 1rem;
      background: none;
      border: none;
      border-radius: 3px;
      color: #b9b9b9;
      padding: 0;
      //transition: all 0.2s ease 0s;

      &:hover {
        color: ${colors.text};
        background: #ededed;
      }
    }
  }

  p.title {
    font-size: 17px;
    font-weight: 500;
  }

  .styled-text-area:focus {
    border: 1px solid;
  }

  @media ${device.lg} {
    top: calc(100% + 12px);
    right: 0;
  }
`;

const FeedbackButton = styled.button`
  background: transparent;
  border: 1px solid white;
  padding: 6px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;

  p {
    margin: 0;
    line-height: 1;
    color: white;
  }

  &:hover {
    opacity: 0.6;
  }

  img {
    width: 16px;
  }
`;

const MobileFeedbackButton = styled(FeedbackButton)`
  padding: 0.65rem 0;

  p {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: ${colors.menu_items};
  }

  img {
    width: 21px;
  }
`;
