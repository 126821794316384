import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, styles } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import { transparentize } from "../../utils/helperFn";

const CtaLink = ({ block = false, children, ...rest }) => {
  return (
    <StyledLink block={block ? 1 : 0} {...rest}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: ${({ block }) => (block ? "block" : "inline-block")};
  border-radius: ${styles.btn_border_radius};

  padding: 10px 30px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  color: ${colors.cta_btn_text};
  border: 1px solid transparent;
  background-color: ${colors.cta_btn};
  box-shadow: 0 0 1rem 0 #3b3f332b;

  &:hover {
    background: ${transparentize(colors.cta_btn, 0.8)} !important;
    color: ${colors.cta_btn_text};
  }
  @media ${device.lg} {
    padding: 14px 46px;
  }

  // BEGIN Hover animation
  position: relative;
  transition: all 0.5s ease-in-out 0s;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: "";
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &::after {
    right: 0;
    left: auto;

    width: 0;

    background-color: ${colors.cta_btn};
  }
  &:hover::after {
    right: auto;
    left: 0;

    width: 100%;
  }
  // END Hover animation
`;

export default CtaLink;
