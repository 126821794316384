import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";

const Main = ({ children, style }) => {
  return <StyledMain style={style}>{children}</StyledMain>;
};

const StyledMain = styled.main`
  margin-top: 52px;

  @media ${device.md} {
    margin-top: 60px;
  }

  @media ${device.lg} {
    margin-top: 0;
  }
`;

export default Main;
