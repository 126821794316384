export const DIGITAL_KISS = "Digital KISS";
export const DIGITAL_KISS_LOGO =
  "/assets/img/logo/logo-digitalkiss-white-text.png";
export const DIGITAL_KISS_LOGO_TEXT =
  "/assets/img/logo/logo-digitalkiss-text.png";
export const WEBSITE_NAME = "Het Vleescentrum";
export const WEBSITE_PHONE_NUMBER = "+31 (0)228-527832";
export const WEBSITE_PHONE_NUMBER_ARIA = "0 0 3 1. 2 2 8. 5 2 7. 8 3 2";
export const WEBSITE_WHATSAPP = "+31636553679";
export const WEBSITE_EMAIL_ADDRESS = "info@hetvleescentrum.nl";
export const WEBSITE_STREET_NUMBER = "De Hoek 91A";
export const WEBSITE_ZIPCODE = "1601MR";
export const WEBSITE_CITY = "Enkhuizen";
export const GOOGLE_MAPS_LINK = "https://maps.app.goo.gl/8K8qXfy75oqZ5mdG8";
export const GOOGLE_MAPS_LAT = "52.69106519850973";
export const GOOGLE_MAPS_LON = "5.266837300000001";
export const SOCIAL_MEDIA = [
  {
    icon: "fa fa-whatsapp",
    link: "https://wa.me/+31636553679",
    name: "WhatsApp",
    value: "+31636553679",
  },
  {
    icon: "fa fa-facebook",
    link: "https://www.facebook.com/HetVleescentrum",
    name: "Facebook",
    value: "@VleescentrumEnkhuizen",
  },
  {
    icon: "fa fa-instagram",
    link: "https://www.instagram.com/hetvleescentrum/",
    name: "Instagram",
    value: "@VleescentrumEnkhuizen",
  },
];
export const PAYMENT_METHODS = [
  { icon: "/assets/img/payment/ideal.svg", name: "iDEAL" },
  { icon: "/assets/img/payment/mastercard.svg", name: "Mastercard" },
  { icon: "/assets/img/payment/visa.svg", name: "Visa" },
  { icon: "/assets/img/payment/bancontact.svg", name: "Bancontact" },
];
export const NATIONAL_WEBSITE_URL = "https://halalvlees-online.nl";
export const LOGO_STORAGE_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Flogo.jpg?alt=media";
export const PRICE_RANGE_INDICATOR = "€";
export const TRUSTPILOT_RATING = 4.9;
export const TRUSTPILOT_REVIEW_AMOUNT = 708;
export const TRUSTPILOT_PAGE_URL =
  "https://nl.trustpilot.com/review/hetvleescentrum.nl";
