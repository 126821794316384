import {
  CLOSE_LOYALTY_PROGRAM_MODAL,
  OPEN_LOYALTY_PROGRAM_MODAL,
} from "../actions/loyaltyModalActions";

const initState = false;

const loyaltyModalReducer = (state = initState, action) => {
  if (action.type === OPEN_LOYALTY_PROGRAM_MODAL) {
    return true;
  }

  if (action.type === CLOSE_LOYALTY_PROGRAM_MODAL) {
    return false;
  }

  return state;
};

export default loyaltyModalReducer;
