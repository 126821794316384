import urls from "../urls";

export const FooterNavItems = [
  {
    label: "about_us",
    url: urls.about,
  },
  {
    label: "recipes",
    url: urls.recipes,
  },
  {
    label: "catering",
    url: urls.horeca,
  },
  {
    label: "packaging",
    url: urls.packaging,
  },
  {
    label: "vacancies",
    url: urls.vacatures,
  },
  {
    label: "contact",
    url: urls.contact,
  },
  {
    label: "returns",
    url: urls.delivery_returns,
  },
  {
    label: "faq",
    url: urls.faq,
  },
];
