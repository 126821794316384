import React from "react";
import styled from "styled-components";
import { colors, styles } from "../../utils/theme";

const CartIncreaser = ({
  amount,
  onPressDecrease,
  onPressIncrease,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <StyledButton className={"dec"} onClick={onPressDecrease}>
        -
      </StyledButton>
      <StyledInput
        className="cart-plus-minus-box"
        type="text"
        value={amount}
        readOnly
      />
      <StyledButton className={"inc"} onClick={onPressIncrease}>
        +
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  display: flex;
  overflow: hidden;

  max-width: 110px;
  height: 40px;
  padding: 0;

  border: 1px solid #e8e8e8;
  border-radius: ${styles.btn_border_radius};
`;

const StyledInput = styled.input`
  font-size: 14px;
  min-width: 28px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #333;
  border: medium none;
  background: transparent none repeat scroll 0 0;
`;

const StyledButton = styled.button`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;

  float: inherit;
  padding: 0px 8px;
  margin: 0;

  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-align: center;

  color: ${colors.primary};
  border: none;
  background: none;

  &.dec {
    border-right: 1px solid #e8e8e8;
  }

  &.inc {
    border-left: 1px solid #e8e8e8;
  }
`;

export default CartIncreaser;
