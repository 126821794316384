import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { Button } from "antd";
import { transparentize } from "../../utils/helperFn";

const AntdCtaBtn = ({ children, ...rest }) => {
  return (
    <StyledButton type={"primary"} {...rest}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  color: ${colors.cta_btn_text} !important;
  background: ${colors.cta_btn} !important;
  border: none !important;
  box-shadow: none;

  &:hover {
    background: ${transparentize(colors.cta_btn, 0.8)} !important;
    color: ${colors.cta_btn_text};
  }
`;

export default AntdCtaBtn;
