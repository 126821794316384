import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/theme";

const BannerTitle = ({ mb, children, ...props }) => {
  return (
    <StyledTitle {...props} mb={mb ? 1 : 0}>
      {children}
    </StyledTitle>
  );
};

const StyledTitle = styled.h2`
  font-weight: 500;
  letter-spacing: ${({ dark }) => (dark ? "1px" : 0)};
  text-align: center;
  margin-bottom: ${({ mb }) => (mb === 1 ? "1rem" : 0)};
  font-family: ${({ dark }) =>
    dark ? '"Chalkduster", sans-serif' : '"Krusyida Script", sans-serif'};
  color: ${({ dark }) => (dark ? "white" : colors.text)};
  font-size: ${({ dark }) =>
    dark ? "clamp(21px, 6vw, 26px)" : "clamp(28px, 7vw, 36px)"};
  line-height: ${({ dark }) => (dark ? "1.4" : "1.2")};
`;

export default BannerTitle;
