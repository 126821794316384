import urls from "../urls";
import { WEBSITE_NAME } from "./CompanyConstants";

// Breadcrumb names
const homepageUrl = process.env.REACT_APP_WEBSITE_URL;
export const BREADCRUMB_HOME = {
  name: WEBSITE_NAME,
  url: homepageUrl,
};
export const BREADCRUMB_PRODUCTS = {
  name: "Producten",
  url: homepageUrl + urls.shop,
};
export const BREADCRUMB_ABOUT = {
  name: "Over Ons",
  url: homepageUrl + urls.about,
};
export const BREADCRUMB_CHECKOUT = {
  name: "Afrekenen",
  url: homepageUrl + urls.checkout,
};
export const BREADCRUMB_CONTACT = {
  name: "Contact",
  url: homepageUrl + urls.contact,
};
export const BREADCRUMB_NOT_FOUND = {
  name: "Niet Gevonden",
  url: homepageUrl + urls.not_found,
};
export const BREADCRUMB_PRIVACY = {
  name: "Privacy Beleid",
  url: homepageUrl + urls.privacy,
};
export const BREADCRUMB_DELIVERY_RETURNS = {
  name: "Verzenden & retourneren",
  url: homepageUrl + urls.delivery_returns,
};
export const BREADCRUMB_FAQ = {
  name: "Veelgestelde Vragen",
  url: homepageUrl + urls.faq,
};

export const BREADCRUMB_SLAGER = {
  name: "Slager",
  url: homepageUrl + urls.slager,
};
export const BREADCRUMB_SLAGER_HOORN = {
  name: "Slager Hoorn",
  url: homepageUrl + urls.slager_hoorn,
};
export const BREADCRUMB_SLAGER_MEDEMBLIK = {
  name: "Slager Medemblik",
  url: homepageUrl + urls.slager_medemblik,
};
export const BREADCRUMB_SLAGER_ENKHUIZEN = {
  name: "Slager Enkhuizen",
  url: homepageUrl + urls.slager_enkhuizen,
};
export const BREADCRUMB_IQ_GRILLS = {
  name: "iQ Grills",
  url: homepageUrl + urls.iq_grills,
};
export const BREADCRUMB_VLEES_GROOTHANDEL = {
  name: "Vlees Groothandel",
  url: homepageUrl + urls.vlees_groothandel,
};
export const BREADCRUMB_HORECA = {
  name: "Horeca Slager",
  url: homepageUrl + urls.horeca,
};
export const BREADCRUMB_RECIPES = {
  name: "Recepten",
  url: homepageUrl + urls.recipes,
};
export const BREADCRUMB_VACATURES = {
  name: "Vacatures",
  url: homepageUrl + urls.vacatures,
};
export const BREADCRUMB_WINKEL = {
  name: "Winkel",
  url: homepageUrl + urls.winkel,
};
export const BREADCRUMB_GRASGEVOERD = {
  name: "Grasgevoerd vlees",
  url: homepageUrl + urls.grasgevoerd,
};
