import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass, closeMenu }) => {
  return (
    <div
      className={`${logoClass ? logoClass : ""}`}
      onClick={closeMenu}
      style={{ overflow: "hidden" }}
    >
      <Link to={"/"} style={{ height: "100%", display: "block" }}>
        <img
          alt="logo"
          src={imageUrl}
          style={{ width: "100%", objectFit: "contain" }}
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
