import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { colors } from "../../utils/theme";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

const DiscountBanner = ({ data, mobileOnly }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const isValid = dayjs(
    data[lang].valid_until_date,
    "DD-MM-YYYY",
  ).isSameOrAfter(dayjs(), "day");

  // Show banner if discount code present in the data
  if (isValid) {
    return (
      <>
        <BannerWrapper fluid mobily_only={mobileOnly}>
          <TextWrapper>
            <SellPhrase
              style={{ color: data[lang].white_text ? "#FFF" : "#000" }}
            >
              <b>{data[lang].selling_text.bold}</b>{" "}
              {data[lang].selling_text.regular}
            </SellPhrase>
            {!!data[lang].discount_code && (
              <DiscountCode>{data[lang].discount_code}</DiscountCode>
            )}
            <ValidUntilText
              style={{ color: data[lang].white_text ? "#FFF" : "#000" }}
            >
              {t("valid_until")} {data[lang].valid_until}
            </ValidUntilText>
          </TextWrapper>
        </BannerWrapper>
      </>
    );
  } else return <></>;
};

const SellPhrase = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4;

  @media ${device.lg} {
    font-size: 15px;
  }
`;

const DiscountCode = styled.span`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #000;
  background-color: #fff;
  margin: 0.25rem 0;
  padding: 0.2rem 0.7rem;

  @media ${device.lg} {
    padding: 0.4rem 1rem 0.4rem 1rem;
    font-size: 18px;
  }
`;

const ValidUntilText = styled.span`
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
`;

const BannerWrapper = styled(Container)`
  background-color: ${colors.backgroundDark};

  @media ${device.lg} {
    display: ${({ mobily_only }) => (mobily_only ? "none" : "block")};
  }
  @media ${device.maxLg} {
    padding: 0.3rem 0 0.3rem 0;
  }
`;

const TextWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 0px;
  @media ${device.maxLg} {
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 4px 3px;
  }
`;

export default DiscountBanner;
