import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import openingHoursNL from "../../data/company/opening_hours.json";
import openingHoursEN from "../../data/company/opening_hours_en.json";
import i18n from "../../translations/i18n";
import { LANGUAGE_NL } from "../../constants/LanguageConstants";
import FooterBottom from "../../components/footer/FooterBottom";
import { useSelector } from "react-redux";
import { formatNameToURL } from "../../helpers/formatters";
import { orderBy } from "lodash";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { styles } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import BannerTitle from "../../components/core/BannerTitle";
import { FooterNavItems } from "../../configs/NavConfigs";

const Footer = ({ containerClass }) => {
  const { t } = useTranslation();
  const [openingHoursData, setOpeningHoursData] = useState(openingHoursNL);
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products,
  );
  const [popularProducts, setPopularProducts] = useState([]);
  const firstColumnProducts = popularProducts.slice(0, 8);
  const secondColumnProducts = popularProducts.slice(8);

  useEffect(() => {
    // Calculate most used categories
    const filteredProducts = products.filter((p) => !!p.purchased);
    const mostOrderedProducts = orderBy(
      filteredProducts,
      ["purchased"],
      ["desc"],
    );
    setPopularProducts(mostOrderedProducts.slice(0, 16));
  }, []);

  useEffect(() => {
    setOpeningHoursData(
      i18n.language === LANGUAGE_NL ? openingHoursNL : openingHoursEN,
    );
  }, [i18n.language]);

  return (
    <StyledFooter>
      <StyledContainer>
        <BannerTitle dark>{t("footer_title")}</BannerTitle>
        <GridContainer>
          <Column>
            <h3>{t("products")}</h3>
            <FooterList>
              {firstColumnProducts.length > 0 &&
                firstColumnProducts.map((product, key) => (
                  <li key={key}>
                    <Link to={urls.product + formatNameToURL(product.url)}>
                      {product.name}
                    </Link>
                  </li>
                ))}
              {secondColumnProducts.length > 0 &&
                secondColumnProducts.map((product, key) => (
                  <li key={key} className={"mobile-visible"}>
                    <Link to={urls.product + formatNameToURL(product.url)}>
                      {product.name}
                    </Link>
                  </li>
                ))}
            </FooterList>
          </Column>
          <Column className={"mobile-hide"}>
            <h3>{t("products")}</h3>
            <FooterList>
              {secondColumnProducts.length > 0 &&
                secondColumnProducts.map((product, key) => (
                  <li key={key}>
                    <Link to={urls.product + formatNameToURL(product.url)}>
                      {product.name}
                    </Link>
                  </li>
                ))}
            </FooterList>
          </Column>
          <Column>
            <h3>{t("customer_service")}</h3>
            <FooterList>
              {FooterNavItems.map((item, index) => (
                <li key={index}>
                  <Link to={item.url}>{t(item.label)}</Link>
                </li>
              ))}
            </FooterList>
          </Column>
          <Column>
            <h3>{t("opening_hours")}</h3>
            <FooterList>
              {openingHoursData.map((item) => (
                <li key={item.id}>
                  <span>
                    {item.title_short}
                    {" - "}
                    {item.value}
                  </span>
                </li>
              ))}
            </FooterList>
          </Column>
        </GridContainer>
      </StyledContainer>
      <FooterBottom />
      <LogoBgElement src={"/assets/img/logo/logo-rond.png"} alt={""} />
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background:
    linear-gradient(transparent, 90%, #000000),
    url("/assets/img/bg/background-dark-1.webp") no-repeat center center / cover;
  color: #fff;
  position: relative;
  isolation: isolate;
  overflow: clip;
`;

const StyledContainer = styled(Container)`
  padding-block: ${styles.banner_padding_mobile};
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media ${device.lg} {
    padding-block: ${styles.banner_padding_desktop};
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 3rem;

  @media only screen and ${device.sm} {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem 8rem;
  }

  @media only screen and ${device.lg} {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-family: "Chalkduster", sans-serif;
    color: white;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .mobile-visible {
    display: block;
    @media ${device.sm} {
      display: none;
    }
  }

  &.mobile-hide {
    display: none;
    @media ${device.sm} {
      display: block;
    }
  }
`;

const FooterList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 9px;

  a,
  span,
  p {
    font-size: 14px;
    color: white;
    opacity: 0.9;
    margin: 0;
  }
  a {
    &:hover {
      color: white;
      opacity: 0.5;
    }
  }
`;

const LogoBgElement = styled.img`
  position: absolute;
  opacity: 0.3;
  z-index: -1;

  left: -10%;
  top: 0;
  bottom: 0;
  margin-block: auto;
  height: 120vw;

  @media ${device.sm} {
    left: -40px;
    height: 75vw;
  }

  @media ${device.lg} {
    left: 0;
    height: 70%;
  }
`;

export default Footer;
