import axios from "axios";
import { WEBSITE_NAME } from "../../constants/CompanyConstants";

export const sendExceptionToSlack = (message) => {
  let websiteName = WEBSITE_NAME;
  if (process.env.REACT_APP_ENVIRONMENT === "development")
    websiteName += " DEV";

  axios
    .post("https://guardian.digitalkiss.nl/api/slack/exception", {
      project: websiteName,
      message,
    })
    .catch((err) => console.log(err));
};
