import React from "react";
import styled from "styled-components";
import GiftIcon from "./GiftIcon";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { device } from "../../utils/breakpoints";

const buttonVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0, transition: { duration: 0.2 } },
};

export default function LoyaltyProgramFab({ onClick, active }) {
  const { t } = useTranslation();
  return (
    <FloatingButton
      onClick={onClick}
      animate={active ? "visible" : "hidden"}
      variants={buttonVariants}
      transition={{ type: "tween", duration: 0.3 }}
      aria-expanded={!active}
      aria-controls="loyalty-program-modal"
      aria-label={t("open_loyalty_program_modal")}
    >
      <GiftIcon />
      <span>{t("rewards")}</span>
    </FloatingButton>
  );
}

const FloatingButton = styled(motion.button)`
  position: fixed;
  bottom: 40px;
  right: 24px;
  background-color: #ffffff;
  width: fit-content;
  border: none;
  border-radius: 1000vw;
  overflow: hidden;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1.5px;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 9;

  span {
    display: none;
  }

  @media ${device.lg} {
    bottom: 52px;
    padding: 6px 12px 6px 6px;

    span {
      display: block;
    }
  }

  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.16),
    0 3px 6px -4px rgba(0, 0, 0, 0.24),
    0 9px 28px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.2),
      0 3px 6px -4px rgba(0, 0, 0, 0.3),
      0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: scale(0.98);
  }
`;
