import React from "react";
import { colors } from "../../utils/theme";
import styled from "styled-components";

export default function GiftIcon({ size = 42, style = null }) {
  return (
    <IconContainer size={size} style={{ ...style }}>
      <img
        src={"/assets/img/loyalty-program/surprise.svg"}
        alt={""}
        className={"gift-icon"}
      />
    </IconContainer>
  );
}

const IconContainer = styled.div`
  --size: ${({ size }) => `${size}px`};

  background-color: ${colors.loyalty_primary};
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  padding: calc(var(--size) / 6);
  display: grid;
  place-content: center;

  .gift-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateX(-9%);
  }
`;
