export const PRODUCT_DETAIL_LARGE_IMAGE = {
  xl: { height: 332 },
  lg: { height: 260 },
  md: { height: 188 },
  sm: { height: 250 },
};

export const PRODUCT_DETAIL_SMALL_IMAGE = {
  xl: { height: 62 },
  lg: { height: 46 },
  md: { height: 99 },
  sm: { height: 47 },
};

export const PRODUCT_DETAIL_VARIANT_IMAGE = {
  xl: { height: 47 },
  lg: { height: 42 },
  md: { height: 65 },
  sm: { height: 46 },
};

export const PRODUCT_DETAIL_RELATED_IMAGE = {
  xl: { height: 182 },
  lg: { height: 155 },
  md: { height: 155 },
  sm: { height: 130 },
};

export const SHOP_LIST_PRODUCT_IMAGE = {
  xl: { height: 180 },
  lg: { height: 220 },
  md: { height: 220 },
  sm: { height: 110 },
};

export const CART_PRODUCT_IMAGE = {
  xl: { height: 78 },
  lg: { height: 61 },
  md: { height: 69 },
  sm: { height: 33 },
};
