import PropTypes from "prop-types";
import React from "react";
import LanguageChanger from "./sub-components/LanguageChanger";
import { useTranslation } from "react-i18next";
import FeedbackWidget from "./sub-components/FeedbackWidget";
import {
  SOCIAL_MEDIA,
  WEBSITE_PHONE_NUMBER,
} from "../../constants/CompanyConstants";
import { useSelector } from "react-redux";
import _ from "lodash";

const HeaderTop = ({ borderStyle }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const whatsappIndex = _.findIndex(SOCIAL_MEDIA, { name: "WhatsApp" });

  return (
    <div
      className={`header-top-wrapper container ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <LanguageChanger />
      <div className="header-top-item d-flex flex-row">
        <p className="m-0 align-self-center">
          {t("call_us")} {WEBSITE_PHONE_NUMBER}
        </p>
      </div>
      <div className="header-top-item d-flex flex-row">
        <a
          href={SOCIAL_MEDIA[whatsappIndex].link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {SOCIAL_MEDIA[whatsappIndex].name}{" "}
          <i className={SOCIAL_MEDIA[whatsappIndex].icon} />
        </a>
      </div>
      <div className="header-offer">
        <p>{t("order_before_slogan")}</p>
      </div>
      {auth.isLoaded && !auth.isEmpty && (
        <div className="header-top-item d-flex flex-row">
          <FeedbackWidget />
        </div>
      )}{" "}
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
