import i18n from "i18next";

export const LIST_TYPE_FEATURED = "FEATURED";
export const LIST_TYPE_PRIORITY = "PRIORITY";
export const FEATURED_PRODUCT_LIST_TYPES = [
  { id: LIST_TYPE_FEATURED, name: i18n.t("featured") },
  {
    id: LIST_TYPE_PRIORITY,
    name: i18n.t("priority"),
  },
];
