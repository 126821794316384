import React, { Fragment, useState } from "react";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { useFirebase } from "react-redux-firebase";
import { formatFbError, types } from "../../services/formatFbError";
import { Form, Input, notification } from "antd";
import { WEBSITE_NAME } from "../../constants/CompanyConstants";
import {
  META_RESET_PASSWORD_DESCRIPTION,
  META_RESET_PASSWORD_TITLE,
} from "../../constants/MetaTagsConstants";
import { Link } from "react-router-dom";
import urls from "../../urls";
import { Helmet } from "react-helmet";
import AntdCtaBtn from "../../components/cta/AntdCtaBtn";
import { AuthCardWrapper } from "../../styles/SharedStyles";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [resetEmailInputError, setResetEmailInputError] = useState(null);
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);
      firebase
        .resetPassword(values.email)
        .then(() => {
          setResetEmailInputError(null);
          notification["success"]({
            message: t("notifications.reset_email_sent"),
          });
          setIsLoading(false);
          form.resetFields();
        })
        .catch((error) => {
          setIsLoading(false);
          let errorMessage = formatFbError(error.code);
          if (errorMessage.type === types.email) {
            setResetEmailInputError(errorMessage.text);
          } else
            notification["warning"]({
              message: errorMessage.text,
            });
        });
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {META_RESET_PASSWORD_TITLE} · {WEBSITE_NAME}
        </title>
        <meta name="description" content={META_RESET_PASSWORD_DESCRIPTION} />
      </Helmet>
      <Layout headerTop="visible">
        <div className="section-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                <div className="login-register-wrapper">
                  <AuthCardWrapper>
                    <h2>{t("reset_password")}</h2>
                    <p>{t("reset_password_page_description")}</p>
                    <Form
                      layout="vertical"
                      name="reset-password-form"
                      onFinish={handleResetPassword}
                      form={form}
                    >
                      <Form.Item
                        name="email"
                        label={t("email")}
                        rules={[
                          {
                            required: true,
                            message: t("form.enter_email"),
                          },
                          {
                            type: "email",
                            message: t("form.enter_valid_email"),
                          },
                        ]}
                        hasFeedback={!!resetEmailInputError}
                        help={resetEmailInputError}
                      >
                        <Input placeholder={t("email")} />
                      </Form.Item>
                      <AntdCtaBtn
                        type="primary"
                        htmlType="submit"
                        block
                        size={"large"}
                        loading={isLoading}
                      >
                        <span>{t("reset")}</span>
                      </AntdCtaBtn>
                      <Link
                        style={{ display: "block", marginTop: 12 }}
                        to={urls.login}
                      >
                        {t("login")}
                      </Link>
                    </Form>
                  </AuthCardWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export default ForgotPassword;
