export const ANALYTICS_PURCHASE = "purchase";
export const ANALYTICS_ADD_TO_CART = "add_to_cart";
export const ANALYTICS_REMOVE_FROM_CART = "remove_from_cart";
export const ANALYTICS_VIEW_ITEM = "view_item";
export const ANALYTICS_SELECT_ITEM = "select_item";
export const ANALYTICS_VIEW_CART = "view_cart";
export const ANALYTICS_BEGIN_CHECKOUT = "begin_checkout";

export const RELATED_PRODUCTS_LIST_ID = "related_products";
export const RELATED_PRODUCTS_LIST_NAME = "Related products";
