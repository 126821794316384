import React from "react";

export const BreadcrumbStructuredData = (breadcrumbList) => {
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbList.map((item, index) => {
          return {
            "@type": "ListItem",
            position: index + 1,
            name: item.name,
            item: item.url,
          };
        }),
      })}
    </script>
  );
};
