export const OPEN_LOYALTY_PROGRAM_MODAL = "OPEN_LOYALTY_PROGRAM_MODAL";
export const CLOSE_LOYALTY_PROGRAM_MODAL = "CLOSE_LOYALTY_PROGRAM_MODAL";

export const openLoyaltyProgramModal = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_LOYALTY_PROGRAM_MODAL,
    });
  };
};

export const closeLoyaltyProgramModal = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_LOYALTY_PROGRAM_MODAL,
    });
  };
};
