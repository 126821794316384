import i18n from "i18next";

/**
 * Get the flag of the current language
 * */
export const getCurrentLanguageFlag = () => {
  const lang = i18n.language;
  switch (lang) {
    case "nl":
      return {
        src: "/assets/img/button-icons/dutch_flag.svg",
        alt: "Nederlandse vlag",
      };
    case "en":
      return {
        src: "/assets/img/button-icons/uk_flag.svg",
        alt: "Engelse vlag",
      };
    default:
      return {
        src: "/assets/img/button-icons/dutch_flag.svg",
        alt: "Nederlandse vlag",
      };
  }
};
