import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PRODUCT_PLACEHOLDER_LOCAL_SRC } from "../../constants/ProductConstants";
import styled from "styled-components";
import { styles } from "../../utils/theme";

const LazyImage = ({ rounded = false, shadow = false, ...props }) => (
  <StyledImage
    {...props}
    effect={"blur"}
    placeholderSrc={PRODUCT_PLACEHOLDER_LOCAL_SRC}
    wrapperProps={{ style: { display: "contents" } }}
    rounded={rounded ? 1 : 0}
    shadow={shadow ? 1 : 0}
  />
);

const StyledImage = styled(LazyLoadImage)`
  border-radius: ${({ rounded }) =>
    rounded === 1 ? styles.card_border_radius : "initial"};
  box-shadow: ${({ shadow }) =>
    shadow ? "0 0 10px 4px rgb(84 84 84 / 15%)" : "initial"};
`;

export default LazyImage;
