/**
 * Generic function for downloading file in de browser
 */
export const downloadFile = (file, fileName) => {
  let url = window.URL.createObjectURL(file);
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

export const isMobile = window.innerWidth <= 768;

export const isTablet = window.innerWidth > 768 && window.innerWidth <= 991;

export const getTop = (el) =>
  el.offsetTop + (el.offsetParent && getTop(el.offsetParent));
