import React, { useEffect, useState } from "react";
import MobileNavMenu from "./sub-components/MobileNavMenu";
import MobileLangCurChange from "./sub-components/MobileLanguageChanger";
import MobileWidgets from "./sub-components/MobileWidgets";
import styled from "styled-components";
import { useLockScroll } from "../hooks/useLockScroll";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { colors } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { formatCategoryName } from "../../helpers/formatters";
import { categorySlug } from "../../urls";

const MobileMenu = ({ active, closeMenu }) => {
  const { t, i18n } = useTranslation();
  const [activeCategory, setActiveCategory] = useState(null);
  const [overflowScroll, setOverflowScroll] = useState(true);

  useLockScroll(active);

  useEffect(() => {
    if (!active) {
      setActiveCategory(null);
    }
  }, [active]);

  useEffect(() => {
    if (!!activeCategory) {
      setTimeout(() => {
        setOverflowScroll(false);
      }, 400);
    } else {
      setOverflowScroll(true);
    }
  }, [activeCategory]);

  return (
    <>
      <AnimatePresence>
        {active && (
          <Wrapper
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{
              duration: 0.4,
              ease: [0.77, 0.2, 0.05, 1],
              type: "tween",
            }}
          >
            <Content className={overflowScroll ? "overflow-scroll" : ""}>
              <MobileNavMenu
                closeMenu={closeMenu}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
              <Divider />
              <MobileLangCurChange />
              <Divider />
              <MobileWidgets />
            </Content>
          </Wrapper>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!!activeCategory && (
          <SubCategoryNav
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: active ? "100%" : "-100%" }}
            transition={{
              duration: 0.4,
              ease: [0.77, 0.2, 0.05, 1],
              type: "tween",
            }}
          >
            <ul>
              {activeCategory.subcategories.map((subcategory) => (
                <li>
                  <MenuLinkItem
                    to={
                      categorySlug +
                      activeCategory.slug +
                      "/" +
                      subcategory.slug
                    }
                    onClick={closeMenu}
                  >
                    {formatCategoryName(subcategory, i18n.language)}
                  </MenuLinkItem>
                </li>
              ))}
              <li>
                <MenuLinkItem
                  to={categorySlug + activeCategory.slug}
                  onClick={closeMenu}
                  className={"all-products"}
                >
                  {t("all_category_products", {
                    category: formatCategoryName(
                      activeCategory,
                      i18n.language,
                    ).toLowerCase(),
                  })}
                </MenuLinkItem>
              </li>
            </ul>
            <button onClick={() => setActiveCategory(null)}>
              {"<< "}
              {t("go_back")}
            </button>
          </SubCategoryNav>
        )}
      </AnimatePresence>
    </>
  );
};

const StyleElement = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    max-width: 50vw;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;
  position: relative;

  &.overflow-scroll {
    overflow-y: scroll;
  }
`;

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100svh;
  background: #fff;
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
`;

const SubCategoryNav = styled(motion.nav)`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: calc(100svh - 44px);
  background: #fff;
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  padding: 2rem 1rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    background: none;
    border: none;
    width: fit-content;
  }
`;

const MenuLinkItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};

  &.all-products {
    font-size: 16px;
    color: ${colors.primary};
  }
`;

export default MobileMenu;
